import { Badge, Button, Card, Notification, Segment, toast } from "components/ui"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { apiAcceptWarranty, apiDownloadWarranty, apiGetSingleWarranty, apiRefundWarranty, apiTerminateWarranty } from "services/LoveWarrantyServices"
import { QUESTIONS, ownerInfoKeys, vehicleInfoKeys } from "../CreateWarranty/constants/steps"
import dayjs from "dayjs"
import { warrantyStatus } from "./helpers/actions"
import { HiDownload, HiOutlineClipboardList } from "react-icons/hi"
import PaymentModal from "./PaymentModal"
import { convertNumbThousand } from "utils/readableNumber"
import { ConfirmDialog } from "components/shared"
import { getDirectDebitSchedule } from 'views/project/ScrumBoard/utils'
import { formatCurrency } from "utils/helpers"
import { BiInfoCircle } from "react-icons/bi"
import { FaComments } from "react-icons/fa"
import { BsClipboardCheck } from "react-icons/bs"
import WarrantyClaims from "./Claims"
import WarrantyNotes from "./Notes"
import { getWarrantyNotes } from "./Notes/store/dataSlice"
import { useDispatch, useSelector } from "react-redux"

export const popNotification = (title, message, type = "info", duration = 5000,) => {
    toast.push(
        <Notification
            title={title}
            type={type}
            duration={duration}
        >
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
}

const WarrantyActions = () => {
    const dispatch = useDispatch()
    const { warrantyNotes } = useSelector((state) => state.warrantyNotes.data)

    const { id } = useParams()
    const [warrantyDetails, setWarrantyDetails] = useState()
    const [activeTab, setActiveTab] = useState(['details'])
    const [confirmDialogOptions, setConfirmDialogOptions] = useState({
        isOpen: false,
        confirmText: 'Confirm',
        message: 'Are you sure you want to proceed?',
        onConfirm: () => { },
        onCancel: () => { },
    })

    // get payment from url 
    const urlParams = new URLSearchParams(window.location.search)
    const payment = urlParams.get('payment')

    if (payment && payment === 'success') {
        // reload the page
        window.location.href = `/app/warranties/edit-warranty/${id}`
    }

    const navigate = useNavigate()
    const [downloading, setDownloading] = useState(false)

    useEffect(() => {
        if (id) {
            // get the warranty details
            const getWarrantyDetails = async () => {
                try {
                    const resp = await apiGetSingleWarranty(id)
                    if (resp && resp.status === 200) {
                        setWarrantyDetails(resp.data)
                        dispatch(getWarrantyNotes(id))

                    } else {
                        const error = resp.message
                        popNotification("Error", error, "danger")
                    }
                } catch (err) {

                }
            }

            getWarrantyDetails()
        }

        // eslint-disable-next-line
    }, [id])

    const onAccept = async () => {
        try {
            const resp = await apiAcceptWarranty({
                id: parseInt(id)
            })
            if (resp && resp.status === 201) {
                popNotification("Success", "Warranty Accepted", "success")
                setWarrantyDetails({
                    ...warrantyDetails,
                    draft: false,
                })
            } else {
                const error = resp.message
                popNotification("Error", error, "danger")
            }
        } catch (err) {
            if (err.response.status === 422) {
                // warranty is already accepted
                popNotification("Info", err.response.data.message, "info")

                setWarrantyDetails({
                    ...warrantyDetails,
                    draft: false,
                })

            } else {
                popNotification("Error", err.response.data.message, "danger")
            }
        }
    }

    const terminateWarranty = async () => {
        try {
            const resp = await apiTerminateWarranty({
                id: parseInt(id)
            })

            if (resp && resp.status === 201) {
                popNotification("Success", "Warranty Terminated", "warning")
                setWarrantyDetails({
                    ...warrantyDetails,
                    draft: false,
                })
            } else {
                const error = resp.message
                popNotification("Error", error, "danger")
            }
        } catch (err) {
            if (err.response.status === 422) {
                // warranty is already accepted
                popNotification("Info", err.response.data.message, "info")

                setWarrantyDetails({
                    ...warrantyDetails,
                    draft: false,
                })

            } else {
                popNotification("Error", err.response.data.message, "danger")
            }
        }
    }

    const refundWarranty = async () => {
        try {
            const resp = await apiRefundWarranty({
                id: parseInt(id)
            })

            if (resp && resp.status === 201) {
                popNotification("Success", "Warranty Refunded", "warning")

                setWarrantyDetails({
                    ...warrantyDetails,
                    refunded: true,
                })
            } else {
                const error = resp.message
                popNotification("Error", error, "danger")
            }
        } catch (err) {
            if (err.response.status === 422) {
                // warranty is already accepted
                popNotification("Info", err.response.data.message, "info")
            } else {
                popNotification("Error", err.response.data.message, "danger")
            }
        }
    }

    const downloadPDF = async () => {
        setDownloading(true)
        const resp = await apiDownloadWarranty(id)

        if (resp) {
            const { url } = resp.data

            const link = document.createElement('a')
            link.href = url
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        }

        setDownloading(false)
    }

    const renderPaymentDetails = () => {
        const payment_method = warrantyDetails.payment_method
        const formmated_payment_method = payment_method === 'direct_debit' ? 'Direct Debit' : 'Card'
        const direct_debit_payments = warrantyDetails.direct_debit_payment ?? {}
        let direct_debit_schedule = warrantyDetails.dealer.direct_debit_schedule ?? "-"

        let direct_debit_last_payment_date = direct_debit_payments.date ? dayjs(direct_debit_payments.date).format("DD/MM/YYYY HH:mm") : "-"
        const direct_debit_payment_date = getDirectDebitSchedule({
            schedule: direct_debit_schedule,
            payment: direct_debit_payments,
            setup: warrantyDetails.dealer.direct_debit_setup
        }, true)

        let direct_debit_payment_status = "Not Paid"
        if (direct_debit_payments.paid && !direct_debit_payments.failed) {
            direct_debit_payment_status = "Paid"
        } else if (direct_debit_payments.failed) {
            direct_debit_payment_status = "Failed"
        }

        // capitalize the first letter
        direct_debit_schedule = direct_debit_schedule.charAt(0).toUpperCase() + direct_debit_schedule.slice(1)

        return (
            <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                <div className="flex justify-between w-full items-center">
                    <h2 className="text-xl font-semibold">
                        <i className="far text-lg fa-money-bill mr-2"></i>
                        Payment
                    </h2>

                    {((warrantyDetails.paid || warrantyDetails.payment_method === 'direct_debit') && !warrantyDetails.refunded) && (
                        <Button
                            size="sm"
                            type="button"
                            variant="solid"
                            onClick={() => {
                                setConfirmDialogOptions({
                                    confirmText: 'Refund',
                                    message: `Are you sure you want to refund ${formatCurrency(warrantyDetails.price)} for this warranty?`,
                                    isOpen: true,
                                    onConfirm: () => {
                                        setConfirmDialogOptions({ ...confirmDialogOptions, isOpen: false })
                                        refundWarranty()
                                    },
                                    onCancel: () => setConfirmDialogOptions({ ...confirmDialogOptions, isOpen: false }),
                                })
                            }}
                        >
                            Refund
                        </Button>
                    )}
                </div>

                <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                    <span className="">Warranty Price: </span>
                    <span className="">{formatCurrency(warrantyDetails.price)}</span>
                </div>
                <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                    <span className="">Payment Method: </span>
                    <span className="">{formmated_payment_method}</span>
                </div>

                {payment_method === 'direct_debit' ? (
                    <>
                        <div className={`${direct_debit_payment_status === "Paid" ? "hidden" : "grid"} grid-cols-2 w-full mt-4 text-black dark:text-gray-100`}>
                            <span className="">Payment Collection Date: </span>
                            <span className="">{direct_debit_payment_date}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                            <span className="">Payment Status: </span>
                            <span className="">{direct_debit_payment_status}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                            <span className="">Date Paid: </span>
                            <span className="">{!warrantyDetails.paid_at ? "Not Paid" : dayjs(warrantyDetails.paid_at).format("DD/MM/YYYY HH:mm")}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 hidden">
                            <span className="">Card: </span>
                            <span className="">XXXX XXXX XXXX {warrantyDetails.card_last_digits}</span>
                        </div>
                    </>
                )}

            </div>
        )
    }

    const renderPolicyDetails = () => {
        return (
            <div className="flex items-center justify-center flex-col w-full mx-auto h-full">
                <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                    <div className="flex justify-between w-full items-center">
                        <h2 className="text-xl font-semibold">
                            <i className="far text-lg fa-user mr-2"></i>
                            Owner information
                        </h2>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 w-full mt-4">
                        {/* Left Column */}
                        <div className="w-full md:w-1/2">
                            {Object.entries(warrantyDetails.customer).slice(0, Math.ceil(Object.entries(warrantyDetails.customer).length / 2)).map((item, index) => (
                                ownerInfoKeys[item[0]] && (
                                    <div className="grid grid-cols-2 w-full text-black dark:text-gray-100 m-3" key={index}>
                                        <span className="">{ownerInfoKeys[item[0]]}: </span>
                                        <span className="">{item[1] ? item[1] : '-'}</span>
                                    </div>
                                )
                            ))}
                        </div>

                        {/* Right Column */}
                        <div className="w-full md:w-1/2">
                            {Object.entries(warrantyDetails.customer).slice(Math.ceil(Object.entries(warrantyDetails.customer).length / 2)).map((item, index) => (
                                ownerInfoKeys[item[0]] && (
                                    <div className="grid grid-cols-2 w-full text-black dark:text-gray-100 m-3" key={index}>
                                        <span className="">{ownerInfoKeys[item[0]]}: </span>
                                        <span className="">{item[1] ? item[1] : '-'}</span>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-col md:flex-row gap-6">
                    <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                        <div className="flex justify-between w-full items-center flex-row  gap-3 mb-2">
                            <h2 className="text-xl font-semibold">
                                <i className="far text-lg fa-circle-info mr-2"></i>
                                Policy information
                            </h2>
                            <div className="flex flex-row justify-end gap-3">
                                {warrantyDetails.draft && (
                                    <Button
                                        className="mx-2"
                                        size="sm"
                                        type="button"
                                        onClick={onAccept}
                                    >
                                        Accept Warranty
                                    </Button>
                                )}
                                {(!warrantyDetails.draft && !warrantyDetails.cancelled && !warrantyDetails.refunded) && (
                                    <PaymentModal
                                        warranty={warrantyDetails}
                                        buttonText={warrantyDetails.payment_method === 'direct_debit' ? 'Update Payment' : 'Pay Now'}
                                        onComplete={() => {
                                            setTimeout(() => {
                                                window.location.href = `/app/warranties/edit-warranty/${id}`
                                            }, 2000)
                                        }} />
                                )}

                                {((!warrantyDetails.cancelled && !warrantyDetails.refunded)) && (
                                    <Button
                                        size="sm"
                                        variant="solid"
                                        type="button"
                                        onClick={() => {
                                            setConfirmDialogOptions({
                                                confirmText: 'Terminate',
                                                message: 'Are you sure you want to terminate this warranty?',
                                                isOpen: true,
                                                onConfirm: () => {
                                                    setConfirmDialogOptions({ ...confirmDialogOptions, isOpen: false })
                                                    terminateWarranty()
                                                },
                                                onCancel: () => setConfirmDialogOptions({ ...confirmDialogOptions, isOpen: false }),
                                            })
                                        }}
                                    >
                                        Terminate
                                    </Button>
                                )}


                            </div>
                        </div>

                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Policy ID:
                            </span>
                            <span className="">LW-{warrantyDetails.id}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Created on:
                            </span>
                            <span className="">{dayjs(warrantyDetails.created_at).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Status
                            </span>
                            <span className="flex gap-2 items-center">
                                <span className={`w-2 h-2 rounded-full ${warrantyStatus(warrantyDetails).dotClass}`}></span>
                                {warrantyStatus(warrantyDetails).label}
                            </span>
                        </div>

                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Coverage:
                            </span>
                            <span className="">{warrantyDetails.product.name}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Excess:
                            </span>
                            <span className="">{formatCurrency(warrantyDetails.product.excess)}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Expiration mileage:
                            </span>
                            <span className="">{warrantyDetails.product.max_milage ? `${convertNumbThousand(warrantyDetails.product.max_milage)} Miles` : "N/A"}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Policy Start Date:
                            </span>

                            <span className="">{dayjs(warrantyDetails.valid_from).format("DD/MM/YYYY")}</span>
                        </div>

                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Expiration Date:
                            </span>
                            <span className="">{dayjs(warrantyDetails.valid_to).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100">
                            <span className="">
                                Policy Duration:
                            </span>
                            <span className="">{warrantyDetails.product.duration_months} Months</span>
                        </div>

                    </div>

                    <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                        <div className="flex justify-between w-full items-center">
                            <h2 className="text-xl font-semibold">
                                <i className="far text-lg fa-car-side mr-2"></i>
                                Vehicle information
                            </h2>
                        </div>
                        {Object.entries(warrantyDetails.vehicle).map((item, index) => (
                            vehicleInfoKeys[item[0]] && (
                                <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 " key={index}>
                                    <span className="">{vehicleInfoKeys[item[0]]}: </span>
                                    <span className="">{item[1] ? item[1] : '-'}</span>
                                </div>
                            )
                        ))}
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                            <span className="">Mileage: </span>
                            <span className="">{warrantyDetails.milage}</span>
                        </div>
                        <div className="grid grid-cols-2 w-full mt-4 text-black dark:text-gray-100 ">
                            <span className="">Vehicle Valuation: </span>
                            <span className="">{formatCurrency(warrantyDetails.vehicle_value)}</span>
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-col md:flex-row gap-6">
                    <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                        <div className="flex justify-between w-full items-center">
                            <h2 className="text-xl font-semibold">
                                <i className="far text-lg fa-list-check mr-2"></i>
                                Vehicle Confirmation information
                            </h2>
                        </div>

                        {QUESTIONS.map((item, index) => (
                            <div className="grid grid-cols-4 w-full mt-4 text-black dark:text-gray-100  gap-5" key={index}>
                                <span className="w-full col-span-3">{item.question}</span>
                                <span className="capitalize flex gap-2 items-center">
                                    <span className={`w - 2 h - 2 rounded - full ${item.expected === 'yes' ? 'bg-green-500' : 'bg-red-500'} `}></span>
                                    {item.expected}
                                </span>
                            </div>
                        ))}
                    </div>

                    {renderPaymentDetails()}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col pb-12 ">
            <h1 className="flex text-2xl font-bold items-center justify-between">
                {warrantyDetails && (
                    <div className="flex flex-col text-2xl font-bold">
                        <div className="flex items-center">
                            <HiOutlineClipboardList className="inline-block mr-2" />
                            <h1 className="text-2xl font-bold">{warrantyDetails.customer.first_name} {warrantyDetails.customer.last_name} {`LW-${id} `}</h1>
                        </div>

                        <span className="dark:text-gray-400 text-sm font-normal">
                            Warranty created on {dayjs(warrantyDetails.created_at).format("DD MMMM YYYY HH:mm")}
                        </span>
                    </div>
                )}

                <Card className={`dark:bg-custom-900 rounded-full lg:block hidden`} bodyClass="rounded-full !p-1">
                    <Segment
                        value={activeTab}
                        onChange={(val) => setActiveTab(val)}
                        size="sm"
                        className="!border-none"
                    >
                        <Segment.Item value="details" className="px-5">
                            <BiInfoCircle className="inline-block mr-2" />
                            Details
                        </Segment.Item>
                        <Segment.Item value="notes" className="px-5">
                            {warrantyNotes.length > 0 ? (
                                <Badge innerClass="!-top-0.5 !-right-2" content={warrantyNotes.length} maxCount={20}>
                                    <FaComments className="inline-block mr-2" />
                                    Notes
                                </Badge>
                            ) : (
                                <>
                                    <FaComments className="inline-block mr-2" />
                                    Notes
                                </>
                            )}
                        </Segment.Item>
                        <Segment.Item value="claims" className="px-5">
                            <BsClipboardCheck className="inline-block mr-2" />
                            Claims
                        </Segment.Item>
                    </Segment>
                </Card>

                <div className="flex gap-2">
                    <Button
                        shape="circle"
                        size="sm"
                        variant="solid"
                        color="custom-900"
                        loading={downloading}
                        onClick={downloadPDF}
                        icon={<HiDownload />}
                    />

                    <Button
                        onClick={() => {
                            navigate(`/app/warranties/warranty-list`)
                        }}
                        size="sm"
                        variant="solid"
                    >
                        Back
                    </Button>
                </div>
            </h1>

            <Card className={`dark:bg-custom-900 rounded-full lg:hidden flex m-auto mt-5`} bodyClass="rounded-full !p-1">
                <Segment
                    value={activeTab}
                    onChange={(val) => setActiveTab(val)}
                    size="sm"
                    className="!border-none"
                >
                    <Segment.Item value="details">Details</Segment.Item>
                    <Segment.Item value="notes">
                        {warrantyNotes.length > 0 ? (
                            <Badge innerClass="!-top-0.5 !-right-2" content={warrantyNotes.length} maxCount={20}>
                                Notes
                            </Badge>
                        ) : (
                            <>
                                Notes
                            </>
                        )}
                    </Segment.Item>
                    <Segment.Item value="claims">Claims</Segment.Item>
                </Segment>
            </Card>

            {(warrantyDetails && activeTab[0] === 'details') && (
                <div>
                    {renderPolicyDetails()}
                </div>
            )}

            {(warrantyDetails && activeTab[0] === 'notes') && (
                <WarrantyNotes warranty_id={warrantyDetails.id} />
            )}

            {(warrantyDetails && activeTab[0] === 'claims') && (
                <WarrantyClaims warranty_id={warrantyDetails.id} />
            )}

            <ConfirmDialog
                isOpen={confirmDialogOptions.isOpen}
                onClose={confirmDialogOptions.onCancel}
                // onRequestClose={}
                type={'warning'}
                title={'Warning'}
                onCancel={confirmDialogOptions.onCancel}
                onConfirm={confirmDialogOptions.onConfirm}
                confirmButtonColor={'custom-300'}
                confirmText={confirmDialogOptions.confirmText}
                cancelText='Cancel'
            >
                <p className="">
                    {confirmDialogOptions.message}
                </p>
            </ConfirmDialog>
        </div>
    )
}

export default React.memo(WarrantyActions)