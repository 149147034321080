import React from 'react'

const WarrantyClaims = ({
    warranty_id
}) => {
    return (
        <div className="flex items-center justify-center flex-col w-full mx-auto h-full">
            <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                <h2 className="text-xl font-semibold">
                    <i className="far text-lg fa-sticky-note mr-2"></i>
                    Claims
                </h2>
                <div className="text-center text-gray-500 dark:text-gray-400 pb-3 text-xl">
                    <img src="/img/others/no-notification.png" alt="No notes" className="w-1/4 mx-auto" />
                    No claims found
                </div>
            </div>
        </div>
    )
}

export default WarrantyClaims