import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetWarrantyNotes } from 'services/LoveWarrantyServices'

export const getWarrantyNotes = createAsyncThunk('warrantyNotes/data/getWarrantyNotes', async (data) => {
    console.log('data', data);

    const response = await apiGetWarrantyNotes(data);
    return response.data;
})

const dataSlice = createSlice({
    name: 'warrantyNotes/data',
    initialState: {
        loading: false,
        warrantyNotes: [],
    },
    reducers: {
        setWarrantyNotes: (state, action) => {
            state.warrantyNotes = action.payload
        },
        discardData: (state) => {
            state.warrantyNotes = []
        }
    },
    extraReducers: {
        [getWarrantyNotes.fulfilled]: (state, action) => {
            state.warrantyNotes = action.payload
            state.loading = false
        },
        [getWarrantyNotes.pending]: (state) => {
            state.loading = true
        },
    },
})

export const {
    discardData,
    setWarrantyNotes
} = dataSlice.actions


export default dataSlice.reducer
