import React from 'react'
import WarrantyNotesList from './WarrantyNotes'
import { injectReducer } from 'store'
import reducer from './store'
import WarrantyNoteCreateForm from './WarrantyNotesForm'

injectReducer('warrantyNotes', reducer)

const WarrantyNotes = ({
    warranty_id
}) => {
    return (
        <div className="flex items-center justify-center flex-col w-full mx-auto h-full">
            <WarrantyNoteCreateForm warranty_id={warranty_id} />

            <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
                <WarrantyNotesList warranty_id={warranty_id} />
            </div>
        </div>
    )
}



export default WarrantyNotes