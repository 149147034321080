
import { Avatar, Timeline } from 'components/ui'
import { useSelector } from 'react-redux'
import { popNotification } from 'views/warranties/CreateWarranty';

export function formatPostDate(date) {
    const postDate = new Date(date);
    // show date as Just now, 1 minute ago, 1 hour ago, 1 day ago, 1 week ago, 1 month ago, 1 year ago
    const currentDate = new Date();
    const diff = currentDate - postDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
        return months === 1 ? '1 month ago' : `${months} months ago`;
    }

    if (weeks > 0) {
        return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    }

    if (days > 0) {
        return days === 1 ? '1 day ago' : `${days} days ago`;
    }

    if (hours > 0) {
        return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }

    if (minutes > 0) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }

    return 'Just now';
}

const TimelineAvatar = ({ children, ...rest }) => {
    return (
        <Avatar {...rest} size={25} shape="circle">
            {children}
        </Avatar>
    )
}
const WarrantyNotesList = () => {
    const { loading, warrantyNotes } = useSelector((state) => state.warrantyNotes.data)

    const onEdit = (id) => {
        popNotification('Feature Not Available', 3000, 'Oops', 'warning')
    }

    const onDelete = (id) => {
        popNotification('Feature Not Available', 3000, 'Oops', 'warning')
    }

    const renderNotes = (notes) => {
        return notes.map((note) => (
            <Timeline.Item
                id={note.id}
                media={
                    <TimelineAvatar className="bg-amber-500">
                        {note.created_by.first_name[0]}{note.created_by.last_name[0]}
                    </TimelineAvatar>
                }
            >
                <p className="my-1 flex items-end gap-2">
                    <span className="font-semibold text-gray-900 dark:text-gray-100">
                        {note.created_by.first_name} {note.created_by.last_name}
                    </span>
                    <span className="text-xs">{formatPostDate(note.created_at)}</span>
                    <div className="flex items-center">
                        <i className="far text-sm fa-edit mr-2 cursor-pointer" onClick={() => {
                            onEdit(note.id)
                        }}></i>
                        <i className="far text-sm fa-trash-alt cursor-pointer" onClick={() => {
                            onDelete(note.id)
                        }}></i>
                    </div>
                </p>
                <div className="flex flex-col w-full mt-4 dark:bg-custom-100 p-4 rounded-lg" dangerouslySetInnerHTML={{ __html: note.body }} />
            </Timeline.Item>
        ))
    }

    return (
        <div className="mt-5">
            {/* Loader */}
            {loading && (
                <div className="text-center text-gray-500 dark:text-gray-400 pb-3 text-lg">
                    <i className="fas fa-spinner fa-spin text-lg mr-2"></i>
                    Loading notes...
                </div>
            )}

            {warrantyNotes.length === 0 && !loading && (
                <div className="text-center text-gray-500 dark:text-gray-400 pb-3 text-xl">
                    <img src="/img/others/no-notification.png" alt="No notes" className="w-1/4 mx-auto" />
                    No notes found
                </div>
            )}

            {warrantyNotes.length > 0 && (
                <Timeline>
                    {renderNotes(warrantyNotes)}
                </Timeline>
            )}
        </div>
    )
}

export default WarrantyNotesList